
























import { Component, Vue,Watch } from "vue-property-decorator";
import MyForm from "@/components/MyForm.vue";
import Mixin from "@/js/mixins";

@Component({ components: { MyForm }, mixins: [Mixin] })
export default class UserInfo extends Vue {
  public searchInfo = {
    head:'',
    name:'',
  }
  public isshow = false;
  public id = '';
  public showLoading = false;
  public member = {};
  public showSuccess = false;
  public user_id = '';
  public info:any = {};
  public number:any = null;
  public canSub:boolean = true;
  public showKeyboard = false;
  public password = "";
  // public weihao = ''
  // 获取用户信息
//   getMember() {
//     let _this = this;
//     this.$api.request({
//       url: "user/member/info",
//       success(res) {
//         _this.member = res.data;
//       }
//     });
//   }
sub(){
  if(this.canSub){
    // this.showKeyboard = true;
    // this.password = "";
    // branch/user/add-branch-user
    let _this = this
    this.$api.request({
      url:'branch/user/add-branch-user',
      data:{
        user_id:this.user_id,
        member_name:this.id
      },
      success(res) {
          
      },
    })
  }
}
@Watch('id')
  getid(e,o){
      this.showLoading = true
      this.isshow = false
      setTimeout(() => {//监听用户输入停止一秒后开始请求
          if(e==this.id){
              
              let _this = this
              this.$api.request({
                url:'branch/user/get-member-info',
                data:{
                  user_id:this.user_id,
                  user_name:this.id
                  // user_name:'MYLS'+this.id
                },
                success(res) {
                    _this.showLoading = false
                    _this.isshow = true
                    if(res.data){
                      _this.searchInfo.head = res.data.head_portrait;
                      _this.searchInfo.name = res.data.realname||res.data.nickname;
                    }else{
                      _this.searchInfo.head = ''
                  _this.searchInfo.name = ''
                    }
                },
                fail(err){
                  _this.searchInfo.head = ''
                  _this.searchInfo.name = ''
                }
              })
          }
      }, 1000);
  }
@Watch("password")
  getpassword(to, from) {
    if (to.length == 6) {
      console.log("进行验证");
      let _this = this
      let id = this.id.substring(0,2);
      this.$api.request({
        url: "balance/user/transfe",
        data: {
          password:this.password,
          user_name:this.id,
          user_id:this.user_id,
          balance:this.number,
          to_user_id:id=='SM'?this.id.substring(2):this.id.substring(4),
        },
        success(res) {
            _this.showSuccess = true;
        },
        fail(err){
            _this.showKeyboard = false
        }
      });
    }
  }
  onDelete() {
    this.password = this.password.slice(0, this.password.length - 1);
  }
  onInput(key) {
    this.password = (this.password + key).slice(0, 6);
  }

// @Watch('number')
// getNumber(e){
//     if(e>0&&Number(e)<this.info.user_balance){//能提交
//         this.canSub = true
//         this.$toast.clear()
//     }else if(e==0){
//         this.canSub = false
//     }else if(Number(e)>this.info.user_balance){
//         this.$toast('已超出当前账户余额！')
//         this.canSub = false
//     }
// }
    get shouxu(){
        return (this.number*this.info.balance_info.withdraw_fee).toFixed(2)
    }
    getInfo() {
    let _this = this;
    this.$api.request({
      url: "balance/user/user-money",
      data:{user_id:this.user_id},
      success(res) {
          _this.info = res.data
          // _this.info.bank.bank_account = _this.info.bank.bank_account.slice(15,20)
      }
    });
  }
  init() {
    this.user_id = String(this.$route.query.user_id || "");
    this.getInfo()
    this.showSuccess = false
    this.number = ''
    
    setTimeout(() => {
      this.showLoading = false
    }, 0);
    this.id = ''  
    // this.isshow = false
  }
}
